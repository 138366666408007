/* eslint-disable no-nested-ternary */

import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {faSyncAlt} from '@fortawesome/free-solid-svg-icons';
import {Badge, Button, Table, Tabs} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import numeral from 'numeral';
import * as subscriptionsService from '../../services/subscriptions';

import stylesLayout from '../../common/layout.module.scss';
import SubscriptionsTable from '../../components/SubscriptionsTable';
import Loading from '../../components/Loading';

import styles from './index.module.scss';

const {TabPane} = Tabs;

const RenewalsModule = ({selectedFleet, user}) => {
  const [loading, setLoading] = useState(false);
  const [subscriptionsByStatus, setSubscriptionsByStatus] = useState(null);
  const [expiredSubscriptionsByDate, setExpiredSubscriptionsByDate] = useState(
    []
  );

  const load = async (showLoading) => {
    if (showLoading) {
      setLoading(true);
    }
    const rspRenewalRequiredSubscriptions = await subscriptionsService.find({
      fleet_id: selectedFleet.id,
      status: 'RENEWAL_REQUIRED',
    });

    const rspSubscriptionsByStatus = {};

    if (rspRenewalRequiredSubscriptions?.status) {
      const byDate = rspRenewalRequiredSubscriptions.data.reduce((acc, c) => {
        const date = moment(c.end_at).format('YYYY-MM-DD');
        if (!(date in acc)) {
          acc[date] = {
            date,
            subscriptions: [],
          };
        }
        acc[date].subscriptions.push(c);
        return acc;
      }, {});
      const expiredVeryOld = rspRenewalRequiredSubscriptions.data.filter(
        (x) => moment(x.end_at) <= moment().subtract(30, 'day')
      );
      const expiredOld = rspRenewalRequiredSubscriptions.data.filter(
        (x) =>
          moment(x.end_at) > moment().subtract(30, 'day') &&
          moment(x.end_at) <= moment().subtract(15, 'day')
      );
      const expired = rspRenewalRequiredSubscriptions.data.filter(
        (x) =>
          moment(x.end_at) > moment().subtract(15, 'day') &&
          moment(x.end_at) <= moment()
      );
      const upcoming = rspRenewalRequiredSubscriptions.data.filter(
        (x) => moment(x.end_at) > moment()
      );
      rspSubscriptionsByStatus.renewal_required_expired_very_old =
        expiredVeryOld;
      rspSubscriptionsByStatus.renewal_required_expired_old = expiredOld;
      rspSubscriptionsByStatus.renewal_required_expired = expired;
      rspSubscriptionsByStatus.renewal_required_upcoming = upcoming;

      setExpiredSubscriptionsByDate(Object.values(byDate));
    }
    setSubscriptionsByStatus(rspSubscriptionsByStatus);
    setLoading(false);
  };

  useEffect(() => {
    load(true);
  }, [selectedFleet.id]);

  return (
    <div className={`${stylesLayout.page}`}>
      <div className={stylesLayout.title}>
        Renovaciones
        <div className={stylesLayout.secAction} style={{marginTop: -5}}>
          <Button
            onClick={() => {
              load(true);
            }}
            size='medium'
          >
            <FontAwesomeIcon icon={faSyncAlt} spin={loading} />
          </Button>
        </div>
      </div>
      <div className={stylesLayout.content}>
        {!loading ? (
          <Tabs>
            {user.can.executive_grin4u && (
              <TabPane tab={<>Resumen por fecha de expiración</>} key='by_date'>
                <Table
                  style={{marginTop: 5}}
                  rowClassName={(r) =>
                    r.date <= moment().subtract(15, 'days').format('YYYY-MM-DD')
                      ? styles.expired_danger
                      : r.date >
                            moment()
                              .subtract(15, 'days')
                              .format('YYYY-MM-DD') &&
                          r.date < moment().format('YYYY-MM-DD')
                        ? styles.expired_warning
                        : null
                  }
                  columns={[
                    {
                      title: 'Fecha de expiración',
                      dataIndex: 'date',
                      key: 'date',
                      render: (v) => v,
                    },
                    {
                      title: 'Cantidad de suscripciones',
                      align: 'center',
                      dataIndex: 'subscriptions',
                      key: 'subscriptions',
                      render: (v) => v.length,
                    },
                    {
                      title: 'Monto total',
                      align: 'center',
                      dataIndex: 'subscriptions',
                      key: 'subscriptions',
                      render: (v) => (
                        <>
                          {selectedFleet.cost?.currency_symbol}{' '}
                          {numeral(
                            v.reduce((acc, c) => acc + c.total, 0)
                          ).format('0,000')}
                        </>
                      ),
                    },
                    {
                      title: 'Suscripciones',
                      align: 'left',
                      dataIndex: 'subscriptions',
                      key: 'subscriptions',
                      render: (v) =>
                        v.map((x) => (
                          <Link
                            to={`/suscripcion/${x.id}`}
                            style={{
                              marginRight: 10,
                              display: 'block',
                              textDecoration: 'none',
                            }}
                          >
                            {[
                              ...Array(
                                parseInt(x.data.breakdown?.qty ?? 1, 10)
                              ).keys(),
                            ].map(() => '🛴 ')}{' '}
                            #{x.id} - {x.user.name}
                          </Link>
                        )),
                    },
                  ]}
                  pagination={{
                    hideOnSinglePage: true,
                    defaultPageSize: 10000,
                  }}
                  dataSource={expiredSubscriptionsByDate}
                />
              </TabPane>
            )}
            {user.can.executive_grin4u && (
              <TabPane
                tab={
                  <>
                    Expiradas más de 30 días{' '}
                    <Badge
                      showZero
                      count={
                        subscriptionsByStatus?.renewal_required_expired_very_old
                          .length || 0
                      }
                      color='var(--mainColor)'
                      overflowCount={999}
                    />
                  </>
                }
                key='renewal_required_expired_very_old'
              >
                <SubscriptionsTable
                  subscriptions={
                    subscriptionsByStatus?.renewal_required_expired_very_old
                  }
                  fleet={selectedFleet}
                  show={['user']}
                />
              </TabPane>
            )}
            {user.can.executive_grin4u && (
              <TabPane
                tab={
                  <>
                    Expiradas entre 15 y 30 días{' '}
                    <Badge
                      showZero
                      count={
                        subscriptionsByStatus?.renewal_required_expired_old
                          .length || 0
                      }
                      color='var(--mainColor)'
                      overflowCount={999}
                    />
                  </>
                }
                key='renewal_required_expired_old'
              >
                <SubscriptionsTable
                  subscriptions={
                    subscriptionsByStatus?.renewal_required_expired_old
                  }
                  fleet={selectedFleet}
                  show={['user']}
                />
              </TabPane>
            )}
            {user.can.executive_grin4u && (
              <TabPane
                tab={
                  <>
                    Expiradas menos de 15 días{' '}
                    <Badge
                      showZero
                      count={
                        subscriptionsByStatus?.renewal_required_expired
                          .length || 0
                      }
                      color='var(--mainColor)'
                      overflowCount={999}
                    />
                  </>
                }
                key='renewal_required_expired'
              >
                <SubscriptionsTable
                  subscriptions={
                    subscriptionsByStatus?.renewal_required_expired
                  }
                  fleet={selectedFleet}
                  show={['user']}
                />
              </TabPane>
            )}
            {user.can.executive_grin4u && (
              <TabPane
                tab={
                  <>
                    Próximas{' '}
                    <Badge
                      showZero
                      count={
                        subscriptionsByStatus?.renewal_required_upcoming
                          .length || 0
                      }
                      color='var(--mainColor)'
                      overflowCount={999}
                    />
                  </>
                }
                key='renewal_required_upcoming'
              >
                <SubscriptionsTable
                  subscriptions={
                    subscriptionsByStatus?.renewal_required_upcoming
                  }
                  fleet={selectedFleet}
                  show={['user']}
                />
              </TabPane>
            )}
          </Tabs>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

RenewalsModule.propTypes = {
  selectedFleet: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default RenewalsModule;
