import React, {useState} from 'react';
import PropTypes from 'prop-types';

// import { useSearchableTable } from '../../hooks/useSearchableTable';
import {Button, notification} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCamera,
  faCheck,
  faSpinner,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import * as subscriptionsService from '../../services/subscriptions';
import * as filesService from '../../services/files';
import DevicesInputWithQR from '../../components/DevicesInputWithQR';
import {getDeviceByTypeQROrRef} from '../../services/devices';

import styles from './index.module.scss';
import WebcamCapture from '../../components/WebcamCapture';

const SubscriptionInRouteActionsModule = ({
  user,
  subscription,
  onChangeStatus,
  onChangeShowCamera,
  showOpenCamera = true,
}) => {
  const [showFullfillForm, setShowFullfillForm] = useState(false);
  const [showPickedForm, setShowPickedForm] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [pickedDeviceList, setPickedDeviceList] = useState([]);
  const [showCamera, setShowCamera] = useState(null);
  const [imgs, setImgs] = useState({});

  const setDeviceQRInList = async (index, qr, type) => {
    if (deviceList.map((x) => x.qr).includes(qr)) {
      notification.error({
        message: `El patín ${qr} ya fue agregado.`,
        placement: 'top',
        key: 'result',
        duration: 0,
      });
      return;
    }
    console.log('getting', qr);
    let device = null;
    let error = null;
    let status = 'pending';
    let newDevice = {qr, status, device};

    if (type === 'fullfill') {
      setDeviceList((prevDeviceList) => {
        const newDeviceList = [...prevDeviceList];
        newDeviceList[index] = newDevice;
        return newDeviceList;
      });
    }

    if (type === 'pickup') {
      setPickedDeviceList((prevDeviceList) => {
        const newDeviceList = [...prevDeviceList];
        newDeviceList[index] = newDevice;
        return newDeviceList;
      });
    }

    const rsp = await getDeviceByTypeQROrRef({
      type: 'scooter',
      code: qr,
    });

    if (rsp?.status) {
      if (type === 'fullfill') {
        if (
          rsp.data.fleet_id === subscription.fleet_id &&
          ['available', 'in_warehouse', 'in_transport_to_user'].includes(
            rsp.data.status
          )
        ) {
          device = rsp.data;
          status = 'ok';
          newDevice = {
            qr,
            status,
            device,
            error,
          };
          setDeviceList((prevDeviceList) => {
            const newDeviceList = [...prevDeviceList];
            newDeviceList[index] = newDevice;
            return newDeviceList;
          });
        } else {
          status = 'error';
          error = `Este patín no puede ser entregado. Estado: ${rsp.data.status_tr}`;
        }
      }
      if (type === 'pickup') {
        const qrInSubscription = subscription.items?.map(
          (item) => item.item?.qr
        );
        console.log(
          'qrInSubscription',
          qrInSubscription,
          rsp.data.status,
          subscription.fleet_id,
          rsp.data.fleet_id
        );
        if (
          rsp.data.fleet_id === subscription.fleet_id &&
          ['customer'].includes(rsp.data.status) &&
          qrInSubscription.includes(qr)
        ) {
          device = rsp.data;
          status = 'ok';
          const pickedItem = subscription.items?.find((x) => x.item?.qr === qr);

          newDevice = {
            qr,
            status,
            pickedItem,
            error,
          };
          setPickedDeviceList((prevPickedDeviceList) => {
            const newDeviceList = [...prevPickedDeviceList];
            newDeviceList[index] = newDevice;
            return newDeviceList;
          });
        } else {
          status = 'error';
          error = `Este patín no puede ser retirado. Estado: ${rsp.data.status_tr}`;
        }

        newDevice = {
          qr,
          status,
          device,
          error,
        };
      }
    } else {
      status = 'error';
    }
  };
  console.log('deviceList', deviceList);

  const setSubscriptionStatus = async (id, status, data = {}) => {
    notification.info({
      message: 'Cambiando estado...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await subscriptionsService.setMultipleStatus(
      [id],
      status,
      data
    );
    if (rsp?.status) {
      notification.success({
        message: 'Estado cambiado.',
        placement: 'top',
        key: 'result',
        duration: 0,
      });
      onChangeStatus(status);
    } else {
      notification.error({
        message: 'Error al cambiar estado.',
        placement: 'top',
        key: 'result',
        duration: 0,
      });
    }
  };
  const startCapture = (type) => {
    setShowCamera(type);
  };
  const uploadImageData = async (type, data) => {
    const imgData = data.substr(data.indexOf(',') + 1);
    setImgs((prevImgs) => ({...prevImgs, [type]: {status: 'uploading'}}));
    const rsp = await filesService.uploadBase64(imgData, 'jpg', 'image/jpeg');
    if (rsp?.status) {
      setImgs((prevImgs) => ({
        ...prevImgs,
        [type]: {status: 'ok', ...rsp.data},
      }));
    } else {
      notification.error({
        message: 'Ocurrio un error al subir la imagen. Intentalo nuevamente',
        placement: 'top',
        key: 'result',
        duration: 0,
      });
    }
  };

  const requireChangeItems =
    subscription.items?.filter(
      (y) => y.status === 'REQUIRE_CHANGE_READY_FOR_SHIPPING'
    ) ?? [];

  const hasItemsChangeReadyForShipping = requireChangeItems.length > 0;
  const imgSuffix = hasItemsChangeReadyForShipping ? '_change' : '';

  const itemsToPick =
    requireChangeItems?.length > 0
      ? requireChangeItems.length
      : subscription.qty;

  const mustBeFullfilled =
    subscription.status === 'READY_FOR_SHIPPING' ||
    hasItemsChangeReadyForShipping;

  const fullfillFormComplete =
    mustBeFullfilled &&
    deviceList.filter((x) => x?.status === 'ok').length === subscription.qty &&
    imgs[`delivery_proof${imgSuffix}`]?.status === 'ok' &&
    imgs[`contract${imgSuffix}`]?.status === 'ok';

  const mustBePicked =
    subscription.status === 'PICKUP_REQUIRED' || hasItemsChangeReadyForShipping;

  const pickupFormComplete =
    mustBePicked &&
    pickedDeviceList.filter((x) => x?.status === 'ok').length === itemsToPick;
  console.log('pickedDeviceList', pickedDeviceList);

  const qtyFullfill = hasItemsChangeReadyForShipping
    ? requireChangeItems.length
    : subscription.qty;

  const qtyPick = qtyFullfill;
  return (
    <div className='flex flex-col gap-2'>
      {!showPickedForm && user.can.transportist_grin4u && mustBePicked && (
        <div>
          <Button
            onClick={() => {
              setShowPickedForm(true);
            }}
          >
            Recoger...
          </Button>
          {/* <Button
                  onClick={() => { setSubscriptionStatus(subscription.id, 'PICKED'); }}
                >
                  Marcar como recogida
                </Button> */}
        </div>
      )}
      {showPickedForm && (
        <div>
          {[...Array(qtyPick).keys()].map((x, i) => (
            <div key={x} className={styles.deviceSelector}>
              <div style={{display: 'inline-block'}}>
                {pickedDeviceList[x]?.status === 'pending' && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    style={{color: 'f60'}}
                  />
                )}
                {pickedDeviceList[x]?.status === 'ok' && (
                  <FontAwesomeIcon icon={faCheck} style={{color: '090'}} />
                )}
                {pickedDeviceList[x]?.status === 'error' && (
                  <FontAwesomeIcon icon={faTimes} style={{color: 'c30'}} />
                )}
              </div>
              <div>
                <DevicesInputWithQR
                  showOpenCamera={showOpenCamera}
                  onChange={(qr) => {
                    setDeviceQRInList(x, qr, 'pickup');
                  }}
                  onChangeShowCamera={onChangeShowCamera}
                  placeholder={`QR ${x + 1} (${requireChangeItems?.[i]?.item?.qr ? `${requireChangeItems?.[i]?.item?.qr} - ` : ''}${subscription.item_type}) `}
                  writable={user.can.admin}
                />
                {pickedDeviceList[x]?.status === 'error' && (
                  <div style={{color: '#c30', marginBottom: 20}}>
                    {pickedDeviceList[x]?.error}
                  </div>
                )}
              </div>
            </div>
          ))}
          <div style={{marginTop: 10}}>
            <Button
              onClick={() => {
                setSubscriptionStatus(subscription.id, 'PICKED', {
                  picked_items: pickedDeviceList.map((x) => x.pickedItem),
                });
              }}
              disabled={!pickupFormComplete}
            >
              Marcar como recogidos
            </Button>
          </div>
          <br />
          {showCamera ? (
            <WebcamCapture
              onClose={() => setShowCamera(false)}
              onCapture={(v) => {
                setShowCamera(false);
                uploadImageData(showCamera, v);
              }}
            />
          ) : null}
        </div>
      )}
      {!showFullfillForm && (
        <>
          {user.can.transportist_grin4u && mustBeFullfilled && (
            <div>
              <Button
                onClick={() => {
                  setShowFullfillForm(true);
                }}
              >
                Entregar...
              </Button>
            </div>
          )}
        </>
      )}
      {showFullfillForm && (
        <div>
          {[...Array(qtyFullfill).keys()].map((x) => (
            <div key={x} className={styles.deviceSelector}>
              <div style={{display: 'inline-block'}}>
                {deviceList[x]?.status === 'pending' && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    style={{color: 'f60'}}
                  />
                )}
                {deviceList[x]?.status === 'ok' && (
                  <FontAwesomeIcon icon={faCheck} style={{color: '090'}} />
                )}
                {deviceList[x]?.status === 'error' && (
                  <FontAwesomeIcon icon={faTimes} style={{color: 'c30'}} />
                )}
              </div>
              <div>
                <DevicesInputWithQR
                  showOpenCamera={showOpenCamera}
                  onChange={(qr) => {
                    setDeviceQRInList(x, qr, 'fullfill');
                  }}
                  onChangeShowCamera={onChangeShowCamera}
                  placeholder={`QR ${x + 1} (${subscription.item_type})`}
                  writable={user.can.admin}
                />
                {deviceList[x]?.status === 'error' && (
                  <div style={{color: '#c30', marginBottom: 20}}>
                    {deviceList[x]?.error}
                  </div>
                )}
              </div>
            </div>
          ))}
          <br />
          <br />
          <div className={styles.imgField}>
            <div className={styles.fieldLabel}>
              Foto de la entrega{' '}
              {imgs[`delivery_proof${imgSuffix}`]?.status === 'uploading' ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                showOpenCamera && (
                  <Button
                    className={styles.fieldButton}
                    onClick={() => startCapture(`delivery_proof${imgSuffix}`)}
                  >
                    <FontAwesomeIcon icon={faCamera} />
                  </Button>
                )
              )}
            </div>
            <div className={styles.fieldContent}>
              {imgs[`delivery_proof${imgSuffix}`]?.status === 'ok' &&
                imgs[`delivery_proof${imgSuffix}`]?.private_url && (
                  <img
                    className={styles.capturedPic}
                    src={imgs[`delivery_proof${imgSuffix}`].private_url}
                    alt=''
                  />
                )}
            </div>
          </div>
          <div className={styles.imgField}>
            <div className={styles.fieldLabel}>
              Contrato{' '}
              {imgs[`contract${imgSuffix}`]?.status === 'uploading' ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                showOpenCamera && (
                  <Button
                    className={styles.fieldButton}
                    onClick={() => startCapture(`contract${imgSuffix}`)}
                  >
                    <FontAwesomeIcon icon={faCamera} />
                  </Button>
                )
              )}
            </div>
            <div className={styles.fieldContent}>
              {imgs[`contract${imgSuffix}`]?.status === 'ok' &&
                imgs[`contract${imgSuffix}`]?.private_url && (
                  <img
                    className={styles.capturedPic}
                    src={imgs[`contract${imgSuffix}`].private_url}
                    alt=''
                  />
                )}
            </div>
          </div>
          <div style={{marginTop: 10}}>
            <Button
              onClick={() => {
                setSubscriptionStatus(subscription.id, 'ACTIVE', {
                  devices: deviceList.map((x) => x.device?.id),
                  documents: Object.keys(imgs).map((k) => ({
                    type: k,
                    ...imgs[k],
                  })),
                });
              }}
              disabled={!fullfillFormComplete}
            >
              Marcar como entregada
            </Button>
          </div>
          <br />
          {showCamera ? (
            <WebcamCapture
              onClose={() => setShowCamera(false)}
              onCapture={(v) => {
                setShowCamera(false);
                uploadImageData(showCamera, v);
              }}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

SubscriptionInRouteActionsModule.propTypes = {
  user: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  showOpenCamera: PropTypes.bool.isRequired,
  onChangeShowCamera: PropTypes.func.isRequired,
};
export default SubscriptionInRouteActionsModule;
