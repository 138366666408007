import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import {Badge, Table} from 'antd';
import {Link} from 'react-router-dom/cjs/react-router-dom.min';
import numeral from 'numeral';

import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {pointNamesByType, subscriptionStatusColor} from '../../helpers/params';

const SubscriptionsTable = ({
  subscriptions,
  rowSelectable,
  show,
  onSelectRows,
  fleet,
}) => {
  const subscriptionsColumns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (v) => <Link to={`/suscripcion/${v}`}>{v}</Link>,
    },
    show.includes('user')
      ? {
          title: 'Cliente',
          dataIndex: 'user',
          key: 'user',
          render: (v) => (
            <>
              <Link to={`/cliente/${v.id}`}>{v?.name}</Link>
              <div
                style={{textDecoration: 'none', color: '#666', fontSize: 12}}
              >
                <a
                  href={`http://wa.me/${v.phone.trim().replaceAll('+', '')}`}
                  target='_blank'
                  rel='noreferrer'
                  style={{textDecoration: 'none', color: '#666'}}
                >
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    style={{color: '#666', fontSize: 12, marginRight: 0}}
                  />
                  {v.phone}
                </a>
              </div>
            </>
          ),
        }
      : null,
    {
      title: 'Plan',
      dataIndex: 'data',
      key: 'plan_name',
      render: (v, r) => <Link to={`/suscripcion/${r.id}`}>{v.plan?.name}</Link>,
    },
    {
      title: 'Patines',
      dataIndex: 'qty',
      align: 'center',
      key: 'qty',
      render: (v, r) => (
        <div className='flex flex-col gap-1 text-center'>
          <div>{v}</div>
          {r.type === 'REQUIRE_CHANGE' ? (
            <div className='text-xxs text-red-500'>
              <FontAwesomeIcon icon={faExclamationTriangle} className='mr-1' />
              Cambio de patín
            </div>
          ) : null}
        </div>
      ),
    },
    show.includes('status')
      ? {
          title: 'Status',
          dataIndex: 'status_tr',
          align: 'center',
          key: 'status_tr',
          render: (v, r) => (
            <Badge
              count={v}
              showZero
              color={subscriptionStatusColor[r.status]}
            />
          ),
        }
      : null,
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (v) => <>{numeral(v).format(fleet.cost?.currency_format)}</>,
    },
    {
      title: 'Lugar de entrega',
      dataIndex: 'data',
      key: 'delivery_point',
      render: (v, r) => (
        <>
          {pointNamesByType[v?.shipping_point]} -{' '}
          {r.user?.data?.grin4uinfo
            ? // eslint-disable-next-line prefer-template
              `${r.user?.data?.grin4uinfo[v?.shipping_point + '_address']}, ${r.user?.data?.grin4uinfo[v?.shipping_point + '_district']}`
            : '-'}
        </>
      ),
    },
    {
      title: 'Creada',
      dataIndex: 'created_at',
      width: 100,
      key: 'created_at',
      sorter: (a, b) =>
        moment(a.created_at).format('X') - moment(b.created_at).format('X'),
      render: (v) => (v ? moment.utc(v).local().format('DD/MM HH:mm') : '-'),
    },
    show.includes('payment_completed_at')
      ? {
          title: 'Pago completado',
          dataIndex: 'payment_complete_at',
          width: 100,
          key: 'payment_complete_at',
          sorter: (a, b) =>
            moment(a.last_renewal?.payment_completed_at).format('X') -
            moment(b.last_renewal?.payment_completed_at).format('X'),
          render: (v, r) =>
            r.last_renewal?.payment_completed_at
              ? moment
                  .utc(r.last_renewal?.payment_completed_at)
                  .local()
                  .format('DD/MM HH:mm')
              : '-',
        }
      : null,
    {
      title: 'Pagada',
      dataIndex: 'last_paid_at',
      sorter: (a, b) =>
        moment(a.last_paid_at).format('X') - moment(b.last_paid_at).format('X'),
      key: 'last_paid_at',
      width: 100,
      render: (v) => (v ? moment.utc(v).local().format('DD/MM HH:mm') : '-'),
    },
    {
      title: 'Comienzo',
      dataIndex: 'start_at',
      sorter: (a, b) =>
        moment(a.start_at).format('X') - moment(b.start_at).format('X'),
      width: 100,
      key: 'start_at',
      render: (v) => (v ? moment.utc(v).local().format('YYYY-MM-DD') : '-'),
    },
    {
      title: 'Fin',
      dataIndex: 'end_at',
      defaultSortOrder: 'ascend',
      sorter: (a, b) =>
        moment(a.end_at).format('X') - moment(b.end_at).format('X'),
      key: 'end_at',
      width: 100,
      render: (v) => (v ? moment.utc(v).local().format('YYYY-MM-DD') : '-'),
    },
  ].filter((x) => !!x);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      onSelectRows(selectedRows.map((s) => s.id));
    },
    getCheckboxProps: (record) => ({
      // Column configuration not to be checked
      id: record.ref,
    }),
  };
  return (
    <Table
      rowSelection={
        rowSelectable && {
          type: 'checkbox',
          ...rowSelection,
        }
      }
      size='small'
      defaultSortOrder='descend'
      pagination={{hideOnSinglePage: true, defaultPageSize: 100}}
      locale={{emptyText: 'Sin suscripciones'}}
      columns={subscriptionsColumns}
      dataSource={
        subscriptions
          ? subscriptions.map((d) => ({
              key: d.id,
              ...d,
            }))
          : []
      }
    />
  );
};

SubscriptionsTable.defaultProps = {
  subscriptions: [],
  show: [],
  rowSelectable: false,
  onSelectRows: () => {},
};
SubscriptionsTable.propTypes = {
  subscriptions: PropTypes.array,
  show: PropTypes.array,
  rowSelectable: PropTypes.bool,
  onSelectRows: PropTypes.func,
  fleet: PropTypes.object.isRequired,
};

export default SubscriptionsTable;
