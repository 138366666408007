/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
// import numeral from 'numeral';
import {Button, DatePicker, Form, notification} from 'antd';

import dayjs from 'dayjs';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import * as subscriptionsService from '../../services/subscriptions';
import * as routesService from '../../services/routes';

import SortableList from '../SortableList';

import styles from './index.module.scss';
import Loading from '../Loading';
import SubscriptionResume from '../SubscriptionResume';

const RouteForm = ({defaultRoute, selectedFleetId, onChange, onClose}) => {
  const [fields, setFields] = useState({
    data: {},
    date: dayjs().add(1, 'days'),
    ...defaultRoute,
  });
  const [subscriptionsInRoute, setSubscriptionsInRoute] = useState(null);
  const [subscriptionsNotInRoute, setSubscriptionsNotInRoute] = useState(null);

  const [saving, setSaving] = useState(false);

  const onFinish = async () => {
    setSaving(true);
    const payload = {
      ...fields,
      date: fields.date.format('YYYY-MM-DD'),
      fleet_id: selectedFleetId,
    };
    if (!payload.data) {
      payload.data = {};
    }
    payload.data.items = subscriptionsInRoute.map((x) => ({
      item_type: 'subscription',
      item_id: x.id,
    }));
    let rsp;
    if (payload.id) {
      notification.info({
        message: 'Actualizando ruta...',
        placement: 'top',
        key: 'result',
        duration: 0,
      });
      rsp = await routesService.update(payload.id, payload);
    } else {
      notification.info({
        message: 'Creando ruta',
        placement: 'top',
        key: 'result',
        duration: 0,
      });
      rsp = await routesService.create(payload);
    }
    if (rsp?.status) {
      notification.destroy();
      onChange();
    }

    setSaving(false);
  };
  const loadReadyForShippingSubscriptions = async () => {
    const rsp = await subscriptionsService.find({
      fleet_id: selectedFleetId,
      status: 'READY_FOR_SHIPPING,PICKUP_REQUIRED',
    });

    if (rsp?.status) {
      setSubscriptionsNotInRoute(rsp.data);
      setSubscriptionsInRoute([]);
    }
  };
  useEffect(() => {
    loadReadyForShippingSubscriptions(true);
  }, []);

  const setField = (field, value) => {
    setFields((s) => ({...s, [field]: value}));
  };

  const getSubElement = (sub, actions) => (
    <SubscriptionResume subscription={sub} actions={actions} />
  );

  return (
    <Form
      name='basic'
      labelCol={{span: 6}}
      wrapperCol={{span: 18}}
      onFinish={onFinish}
      autoComplete='off'
    >
      <div>
        <Form.Item label='Fecha'>
          <DatePicker
            style={{width: '100%'}}
            defaultValue={fields.date}
            onChange={(v) => setField('date', v)}
          />
        </Form.Item>
      </div>
      <div>
        {subscriptionsInRoute !== null ? (
          <div className={styles.subsRoute}>
            <div className={styles.inRoute}>
              <div className={styles.head}>En ruta</div>
              <SortableList
                onChange={(els) => {
                  setSubscriptionsInRoute(els.map((x) => x.data));
                }}
                items={subscriptionsInRoute.map((x) => ({
                  key: x.id,
                  data: x,
                  el: getSubElement(
                    {
                      ...x,
                      type:
                        (x.items || []).filter(
                          (y) =>
                            y.status === 'REQUIRE_CHANGE_READY_FOR_SHIPPING'
                        ).length > 0
                          ? 'REQUIRE_CHANGE'
                          : 'NORMAL',
                      qty:
                        x.items?.filter(
                          (y) =>
                            y.status === 'REQUIRE_CHANGE_READY_FOR_SHIPPING'
                        ).length > 0
                          ? x.items?.filter(
                              (y) =>
                                y.status === 'REQUIRE_CHANGE_READY_FOR_SHIPPING'
                            ).length
                          : x.qty,
                    },
                    <>
                      <Button
                        type='secondary'
                        onClick={() => {
                          setSubscriptionsNotInRoute((p) => [...p, x]);
                          setSubscriptionsInRoute((p) =>
                            p.filter((e) => e.id !== x.id)
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                    </>
                  ),
                }))}
              />
            </div>
            <div className={styles.notInRoute}>
              <div className={styles.head}>Fuera de ruta</div>
              {subscriptionsNotInRoute?.map((x) =>
                getSubElement(
                  {
                    ...x,
                    type:
                      (x.items || []).filter(
                        (y) => y.status === 'REQUIRE_CHANGE_READY_FOR_SHIPPING'
                      ).length > 0
                        ? 'REQUIRE_CHANGE'
                        : 'NORMAL',
                    qty:
                      x.items?.filter(
                        (y) => y.status === 'REQUIRE_CHANGE_READY_FOR_SHIPPING'
                      ).length > 0
                        ? x.items?.filter(
                            (y) =>
                              y.status === 'REQUIRE_CHANGE_READY_FOR_SHIPPING'
                          ).length
                        : x.qty,
                  },
                  <>
                    <Button
                      type='secondary'
                      onClick={() => {
                        setSubscriptionsNotInRoute((p) =>
                          p.filter((e) => e.id !== x.id)
                        );
                        setSubscriptionsInRoute((p) => [...p, x]);
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </>
                )
              )}
            </div>
          </div>
        ) : (
          <Loading />
        )}
        <Form.Item>
          <Button type='primary' htmlType='submit' disabled={saving}>
            Guardar
          </Button>{' '}
          <Button type='link' danger onClick={() => onClose()}>
            Cerrar
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};
RouteForm.defaultProps = {
  defaultRoute: {},
  onChange: () => {},
  onClose: () => {},
};
RouteForm.propTypes = {
  defaultRoute: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  selectedFleetId: PropTypes.number.isRequired,
};

export default RouteForm;
