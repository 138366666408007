import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useParams, Link} from 'react-router-dom';

import {Tabs} from 'antd/es';
import TabPane from 'antd/es/tabs/TabPane';
import * as routesService from '../../services/routes';

import Loading from '../../components/Loading';
import SubscriptionResume from '../../components/SubscriptionResume';
import SubscriptionInRouteActionsModule from '../SubscriptionInRouteActionsModule';

import stylesLayout from '../../common/layout.module.scss';
import styles from './index.module.scss';

const RouteModule = ({user, selectedFleet}) => {
  const [showOpenCamera, setShowOpenCamera] = useState(true);
  const [loading, setLoading] = useState(true);
  const [routes, setRoutes] = useState(null);
  const {id: idParam} = useParams();

  const loadRoute = async (id) => {
    setLoading(true);
    let rsp;
    if (id) {
      rsp = await routesService.get(id);
      if (rsp?.status) {
        setRoutes([rsp.data]);
      } else {
        setRoutes(null);
      }
    } else {
      rsp = await routesService.getToday(selectedFleet.id);
      if (rsp?.status) {
        setRoutes(rsp.data);
      } else {
        setRoutes(null);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    loadRoute(idParam);
  }, [idParam]);

  if (loading) {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>
          <Loading />
        </div>
      </div>
    );
  }
  console.log('routes', routes);
  if (!routes) {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>
          Ruta no encontrada
          <br />
          <Link to='/rutas'>Ver todas</Link>
        </div>
      </div>
    );
  }
  const routesWithStats = routes.map((route) => {
    const statsByType = route.items.reduce((acc, item) => {
      if (!acc[item.item_type]) {
        acc[item.item_type] = {
          qty: 0,
          qty_ready_for_shipping: 0,
          qty_active: 0,
          qty_pickup_required: 0,
          qty_picked: 0,
        };
      }
      acc[item.item_type].qty += item.qty;

      if (item.status === 'READY_FOR_SHIPPING') {
        acc[item.item_type].qty_ready_for_shipping += parseInt(item.qty, 10);
      }
      if (item.status === 'ACTIVE') {
        acc[item.item_type].qty_active += parseInt(item.qty, 10);
      }
      if (item.status === 'PICKup_REQUIRED') {
        acc[item.item_type].qty_pickup_required += parseInt(item.qty, 10);
      }
      if (item.status === 'PICKED') {
        acc[item.item_type].qty_picked += parseInt(item.qty, 10);
      }
      return acc;
    }, {});
    return {
      ...route,
      statsByType,
    };
  });

  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>Ruta {routes[0].date}</div>
      <div className={stylesLayout.content}>
        <Tabs>
          {routesWithStats.map((route) => (
            <TabPane tab={<>Ruta #{route.id}</>} key={`route-${route.id}`}>
              <div className={styles.metrics}>
                {Object.keys(route.statsByType).map((x) => (
                  <div className={styles.metric} key={x}>
                    <div className={styles.title}>{x}</div>
                    <div className={styles.data}>
                      Por entregar:{' '}
                      {route.statsByType[x].qty_ready_for_shipping}
                      <br />
                      Entregados: {route.statsByType[x].qty_active}
                      <br />
                      Por recoger: {route.statsByType[x].qty_pickup_required}
                      <br />
                      Recogidas: {route.statsByType[x].qty_picked}
                    </div>
                  </div>
                ))}
              </div>
              {route.items?.map((item) => (
                <SubscriptionResume
                  key={item.id}
                  imgSuffix=''
                  subscription={{
                    ...item,
                    type:
                      (item.items || []).filter(
                        (y) => y.status === 'REQUIRE_CHANGE_READY_FOR_SHIPPING'
                      ).length > 0
                        ? 'REQUIRE_CHANGE'
                        : 'NORMAL',
                    qty:
                      item.items?.filter(
                        (y) => y.status === 'REQUIRE_CHANGE_READY_FOR_SHIPPING'
                      ).length > 0
                        ? item.items?.filter(
                            (y) =>
                              y.status === 'REQUIRE_CHANGE_READY_FOR_SHIPPING'
                          ).length
                        : item.qty,
                  }}
                >
                  <SubscriptionInRouteActionsModule
                    subscription={item}
                    showOpenCamera={showOpenCamera}
                    user={user}
                    onChangeShowCamera={(isCameraOpen) => {
                      console.log('isCameraOpen', isCameraOpen);
                      setShowOpenCamera(!isCameraOpen);
                    }}
                    onChangeStatus={() => {
                      loadRoute(route.id);
                    }}
                  />
                </SubscriptionResume>
              ))}
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

RouteModule.propTypes = {
  user: PropTypes.object.isRequired,
  selectedFleet: PropTypes.object.isRequired,
};

export default RouteModule;
