/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */

import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isMobile from 'is-mobile';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faLock,
  faUnlock,
  faPlug,
  faSync,
  faLocationArrow,
  faCog,
  faExternalLinkAlt,
  faCopy,
  faVolumeUp,
  faVolumeMute,
  faExclamationCircle,
  faPowerOff,
  faStar,
  faBan,
  faWrench,
  faMapPin,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

import {Button, Checkbox, Input, Modal, notification, Select, Tabs} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {getTripQualificationColor} from '../../../helpers/utils';
import {setDevicesStatus, setDeviceFleet} from '../../../services/fleets';

import wifi0 from '../../../assets/signal/wifi-0.svg';
import wifi1 from '../../../assets/signal/wifi-1.svg';
import wifi2 from '../../../assets/signal/wifi-2.svg';
import wifi3 from '../../../assets/signal/wifi-3.svg';
import wifi4 from '../../../assets/signal/wifi-4.svg';

import purplePoint from '../../../assets/points/purple.svg';
import grayPoint from '../../../assets/points/gray.svg';

import MqttSubscriptionList from '../../MqttSubscriptionList';
import CopyWithMessage from '../../CopyWithMessage';
import EventsList from '../../EventsList';

import stylesLayout from '../../../common/layout.module.scss';
import styles from './index.module.scss';

import {
  networks,
  getBatteryIcon,
  statusTranslations,
  brands,
} from '../../../helpers/params';
import {
  getDeviceByTypeQROrRef,
  getCommandForAction,
  sendDeviceCommand,
  getEvents,
  setDeviceBrand,
  getStatusTransitionsAllowed,
} from '../../../services/devices';

import {createEvent} from '../../../services/events';

import {MapComponent} from '../../MapComponent';
import DeviceDamageModule from '../../../modules/DeviceDamageModule';
import LocationPicker from '../../LocationPicker';
import DeviceReparationModule from '../../../modules/DeviceReparationModule';

const {TabPane} = Tabs;

const {Option} = Select;

const MobileScooterInfo = ({
  user,
  defaultDevice,
  fleets,
  openActions,
  position,
  updating,
  buttonCols,
  pollPeriod,
}) => {
  const [showActions, setShowActions] = useState(openActions);
  const [confirmedPosition, setConfirmedPosition] = useState();
  const [device, setDevice] = useState(defaultDevice);
  const [yourLocationMapVisible, setYourLocationMapVisible] = useState(false);
  const [statusSendMessage, setStatusSendMessage] = useState('');
  const [statusTransitionsAllowed, setStatusTransitionsAllowed] = useState({});
  const [changeLocationOnUpdate, setChangeLocationOnUpdate] =
    useState(isMobile());

  const [loadingEvents, setLoadingEvents] = useState(false);
  const [events, setEvents] = useState(false);

  const changeFleet = async (fleetId) => {
    notification.destroy('result');
    notification.info({
      message: 'Cambiando flota...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await setDeviceFleet(device.ref, fleetId);
    if (rsp?.status) {
      notification.success({
        message: 'Flota cambiada con éxito.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al cambiar flota.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const changeBrand = async (brand) => {
    notification.destroy('result');
    notification.info({
      message: 'Cambiando branding...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await setDeviceBrand(device.ref, brand);
    if (rsp?.status) {
      notification.success({
        message: 'Branding cambiado con éxito.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al cambiar el branding.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const loadEvents = async (limit = 200, beforeId = null, refresh = true) => {
    setLoadingEvents(true);
    const rsp = await getEvents(device.ref, device.type, limit, beforeId);
    if (rsp && rsp.data) {
      if (refresh) {
        setEvents(rsp.data);
      } else {
        setEvents([...(events || []), ...rsp.data]);
      }
    }
    setLoadingEvents(false);
  };

  const updateDevice = async () => {
    if (!device) {
      return;
    }
    const rsp = await getDeviceByTypeQROrRef({
      type: device.type,
      code: device.ref,
    });
    if (rsp && rsp.status) {
      setDevice(rsp.data);
    }
  };

  const moveDevicesToStatus = async (
    status,
    data = {},
    allowEvenIfNotUpdateLocation = false
  ) => {
    if (!allowEvenIfNotUpdateLocation && !changeLocationOnUpdate) {
      Modal.confirm({
        width: 800,
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            ¿Estás seguro que{' '}
            <strong>no quieres poner los patines en tu ubicación actual</strong>
            ?
          </div>
        ),
        okText: 'Si',
        okButtonProps: {danger: true},
        cancelText: 'No',
        onOk() {
          moveDevicesToStatus(status, data, true);
        },
        onCancel() {},
      });
      return;
    }

    notification.destroy('result');
    notification.info({
      message: 'Cambiando estado...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await setDevicesStatus({
      devices: [device.id],
      status,
      selectedUserId: user.id,
      setLocation: changeLocationOnUpdate,
      data,
    });
    if (rsp && rsp.status) {
      updateDevice();

      notification.destroy('result');
      notification.success({
        message: 'Cambiado con éxito.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.destroy('result');
      notification.error({
        message: 'Ocurrió un error al cambiar el estado.',
        placement: 'top',
        key: 'result',
      });
    }
  };
  const onMarkReset = async () => {
    notification.destroy('result');
    notification.info({
      message: 'Marcando...',
      placement: 'top',
      duration: 0,
      key: 'result',
    });
    const payload = {
      event: 'device:reset',
      item_type: 'device',
      user_id: user.id,
      item_id: device.id,
      lat: position.latitude,
      lng: position.longitude,
    };
    await createEvent(payload);

    notification.success({
      message: 'Marcado con éxito.',
      placement: 'top',
      key: 'result',
    });
  };

  const onShowYourLocationMap = async () => {
    setYourLocationMapVisible(true);
  };

  const onMarkFound = async () => {
    notification.destroy('result');
    notification.info({
      message: 'Confirmando ubicación del patín...',
      placement: 'top',
      duration: 0,
      key: 'result',
    });
    const payload = {
      event: 'device:found',
      item_type: 'device',
      user_id: user.id,
      item_id: device.id,
      lat: confirmedPosition.lat,
      lng: confirmedPosition.lng,
      data: {
        device,
      },
    };
    await createEvent(payload);
    notification.success({
      message: 'Ubicación del patín confirmada.',
      placement: 'top',
      key: 'result',
    });
    setYourLocationMapVisible(false);
    updateDevice();
  };

  const onMarkNotFound = async () => {
    notification.destroy('result');
    notification.info({
      message: 'Marcando No encontrado...',
      placement: 'top',
      duration: 0,
      key: 'result',
    });
    const payload = {
      event: 'device:not_found',
      item_type: 'device',
      user_id: user.id,
      item_id: device.id,
      lat: position.latitude,
      lng: position.longitude,
      data: {
        device,
      },
    };
    await createEvent(payload);

    notification.success({
      message: 'Marcado No encontrado con éxito.',
      placement: 'top',
      key: 'result',
    });
  };
  const onAction = async (action, params = null, showNotification = true) => {
    const command = getCommandForAction(action, params);
    if (!command) {
      return null;
    }
    if (showNotification) {
      notification.destroy('result');
      notification.info({
        message: 'Enviando comando...',
        placement: 'top',
        duration: 0,
        key: 'result',
      });
    }
    const payload = {
      event: 'device:send_command',
      item_type: 'device',
      data: {
        command,
        action,
      },
      user_id: user.id,
      item_id: device.id,
      lat: position.latitude,
      lng: position.longitude,
    };
    createEvent(payload);
    const rsp = await sendDeviceCommand({
      type: device.type,
      ref: device.ref,
      command,
      sync: true,
      useBackup: device.data?.iot_firmware_version >= 35004,
    });
    if (rsp?.status) {
      if (showNotification) {
        console.info('SHOW SUCCESS');
        notification.success({
          message: (
            <>
              Enviado con éxito.
              <br />
              <small>{JSON.stringify(rsp.data?.response)}</small>
            </>
          ),
          placement: 'top',
          maxCount: 1,
          key: 'result',
        });
      }
      if (rsp.data?.response?.command === 201) {
        setDevice({
          ...device,
          lat: parseFloat(rsp.data?.response?.response['position.latitude']),
          lng: parseFloat(rsp.data?.response.response['position.longitude']),
        });
      }
    } else if (showNotification) {
      notification.error({
        message: 'Ocurrió un error al enviar el comando. Intenta nuevamente.',
        placement: 'bottom',
        key: 'result',
        maxCount: 1,
      });
    }
    updateDevice();
    return rsp?.status;
  };

  const loadStatusTransitionsAllowed = async () => {
    const rsp = await getStatusTransitionsAllowed(device.fleet_id);
    if (rsp?.status) {
      setStatusTransitionsAllowed(rsp.data);
    }
  };

  useEffect(() => {
    setShowActions(openActions);
  }, [openActions]);
  useEffect(() => {
    setDevice(defaultDevice);
  }, [defaultDevice]);

  useEffect(() => {
    // onAction('get_lock_status', null, false);
    if (!device) {
      return null;
    }
    setStatusSendMessage(`🚨🚨 ${device.qr} - ${device.fleet?.name || 'Sin Flota'} - Sospecha de robo

https://www.google.com/maps/search/?api=1&query=${device.lat},${device.lng}
        
https://rental.grow.mobi/device/${device.qr}

${device.data?.locked === 0 ? 'Desbloqueado' : 'Bloqueado'} - ${parseInt(device.data?.engine_on, 10) === 0 ? 'Motor apagado' : 'Motor encendido'} - ${device.data?.batsco}% de batería

${device.data.instant_velocity_km_h > 0 ? `Velocidad instantánea: ${device.data.instant_velocity_km_h} km/h` : ''}
Últ Posición: ${device.geo_updated_at ? `${moment.utc(device.geo_updated_at).fromNow()}` : 'Sin info'}
Últ mensaje: ${device.data.last_message_from_device_at ? `${moment.utc(device.data.last_message_from_device_at).fromNow()}` : 'Sin info'}
Últ HB: ${device.data.last_heartbeat_from_device_at ? `${moment.utc(device.data.last_heartbeat_from_device_at).fromNow()}` : 'Sin info'}`);

    const pid = window.setInterval(updateDevice, pollPeriod);
    return () => {
      if (pid) {
        window.clearInterval(pid);
      }
    };
  }, [device]);
  useEffect(() => {
    loadStatusTransitionsAllowed();
  }, [device?.fleet_id]);
  useEffect(() => {
    setConfirmedPosition({
      lat: position.latitude,
      lng: position.longitude,
    });
  }, [position]);

  if (!device) {
    return null;
  }

  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyCIi-49zQc_qm-8TCRX26suYgt0oL1YETw&scale=2&center=${device.lat},${device.lng}&zoom=16&size=350x350&maptype=roadmap&markers=color:green%7Clabel:G%7C${device.lat},${device.lng}`;
  let connectionColor = null;
  let signalLevel = null;
  let signalIcon = wifi0;
  if (device.data?.rssi) {
    if (device.data?.rssi < 10) {
      connectionColor = '#f60';
      signalLevel = 'Muy baja señal';
      signalIcon = wifi1;
    } else if (device.data?.rssi < 20) {
      connectionColor = '#fc0';
      signalLevel = 'Baja señal';
      signalIcon = wifi2;
    } else if (device.data?.rssi < 30) {
      connectionColor = '#06c';
      signalLevel = 'Señal buena';
      signalIcon = wifi3;
    } else {
      signalLevel = 'Excelente señal';
      connectionColor = '#093';
      signalIcon = wifi4;
    }
  }
  if (!device.is_online_last_30s) {
    signalIcon = wifi0;
    connectionColor = '#c30';
  }

  const newAvailableStatuses = (statusTransitionsAllowed[device.status] || [])
    .map((x) => ({value: x, label: statusTranslations[x] || x}))
    .sort((a, b) => a.label.localeCompare(b.label));

  const hasAccessToDeviceFleet =
    device?.fleet && fleets && fleets.find((x) => x.id === device.fleet?.id);
  let markersInMap = [device];
  if (device?.data.last_positions) {
    const useLastPositions = device?.data.last_positions
      .filter((x) => x.lat !== device.lat && x.lng !== device.lng)
      .slice(0, 10)
      .map((x, i) => ({
        ...x,
        latitude: x.lat,
        longitude: x.lng,
        zIndex: 1,
        icon: x.precise ? purplePoint : grayPoint,
        iconScale: new window.google.maps.Size(30 - 3 * i, 30 - 3 * i),
        anchor: new window.google.maps.Point((30 - 3 * i) / 2, 30 - 3 * i),

        label: <>{moment.utc(x.ts).fromNow()}</>,
      }));

    markersInMap = markersInMap.concat(useLastPositions);
  }

  const hasIot = device.data?.iot_type !== 'no';
  if (yourLocationMapVisible && position && position.latitude) {
    return (
      <div className={styles.cntLocationPicker}>
        <div className={styles.cntLocationPickerMap}>
          <LocationPicker
            editable
            defaultLocation={{
              lat: confirmedPosition.lat,
              lng: confirmedPosition.lng,
            }}
            onChange={(e) => {
              setConfirmedPosition(e);
            }}
          />
        </div>
        <div className={styles.cntLocationPickerButtons}>
          <small style={{textAlign: 'center'}}>
            * Para cambiar la ubicación arrastra el punto
          </small>
          <br />

          <Button size='big' block onClick={() => onMarkFound()} type='primary'>
            <FontAwesomeIcon icon={faMapPin} /> Confirmar ubicación
          </Button>
          <br />
          <Button
            size='big'
            block
            onClick={() => setYourLocationMapVisible(false)}
            type='secondary'
          >
            Volver
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.cnt}>
      <div className={styles.info}>
        <Tabs className={styles.tabs}>
          <TabPane tab={<>Resumen</>} key='resume'>
            <>
              <div className={styles.statusIcons}>
                <div
                  className={styles.connectionStatus}
                  onClick={() => onAction('get_lock_status')}
                >
                  <img
                    alt=''
                    title={`${device.is_online_last_30s ? 'Online (últ 30s)' : 'Offline (últ 30s)'} ${signalLevel && ` - ${signalLevel}`}`}
                    src={signalIcon}
                    style={{
                      width: 25,
                      marginTop: -2,
                      marginLeft: 12,
                      color: connectionColor,
                    }}
                  />
                  <div className={styles.legend}>
                    {device.is_online_last_30s
                      ? 'Online (últ 30s)'
                      : 'Offline (últ 30s)'}
                    {signalLevel && ` - ${signalLevel}`}
                  </div>
                </div>
                <div
                  className={styles.lockStatus}
                  onClick={() => onAction('get_lock_status')}
                >
                  <FontAwesomeIcon
                    icon={
                      device.data.locked === 1
                        ? faLock
                        : device.data.locked === 0
                          ? faUnlock
                          : null
                    }
                    className={
                      device.data.locked ? styles.locked : styles.unlocked
                    }
                  />
                  <div className={styles.legend}>
                    {device.data.locked ? 'Bloqueado' : 'Desbloqueado'}
                  </div>
                </div>
                <div className={styles.engineStatus}>
                  <FontAwesomeIcon
                    icon={faCog}
                    className={
                      device.data.engine_on === '1'
                        ? styles.engineOn
                        : styles.engineOff
                    }
                  />
                  <br />
                  <div className={styles.legend}>
                    {device.data.engine_on === '1'
                      ? 'Motor encendido'
                      : 'Motor apagado'}
                  </div>
                </div>
                <div
                  className={styles.battery}
                  onClick={() => onAction('get_battery')}
                >
                  <div className={styles.info}>
                    <FontAwesomeIcon
                      icon={getBatteryIcon(device.data.batsco)}
                      style={{fontSize: 18}}
                    />{' '}
                    {device.data.charging === '1' ? (
                      <FontAwesomeIcon icon={faPlug} style={{fontSize: 12}} />
                    ) : null}{' '}
                    {device.data.batsco}
                  </div>
                  <div className={styles.legend}>Batería</div>
                </div>
              </div>
              {!device.fleet_id && (
                <div
                  style={{
                    background: '#c30',
                    color: '#fff',
                    padding: 20,
                    fontWeight: 'bold',
                  }}
                >
                  Patín sin flota
                </div>
              )}
              {[1, 6].includes(device.fleet_id) && (
                <div style={{marginBottom: 20}}>
                  <DeviceDamageModule
                    user={user}
                    deviceRef={device.ref}
                    damages={device.data.damages}
                    onUpdateDamage={() => updateDevice()}
                  />
                </div>
              )}
              {user?.can?.repair && (
                <div style={{marginBottom: 20}}>
                  <DeviceReparationModule
                    deviceRef={device.ref}
                    reparations={device.data.reparations}
                    onUpdateReparation={() => updateDevice()}
                  />
                </div>
              )}
              <table className={styles.table}>
                <tr>
                  <td width='25%'>Id, QR, Ref, SIM</td>
                  <td>
                    <CopyWithMessage
                      inline
                      value={device.id}
                      messageCopied='Copiado!'
                      messageTime={2000}
                    >
                      {device.id}{' '}
                      <FontAwesomeIcon icon={faCopy} style={{fontSize: 16}} />
                    </CopyWithMessage>
                    ,{' '}
                    <CopyWithMessage
                      inline
                      value={device.qr}
                      messageCopied='Copiado!'
                      messageTime={2000}
                    >
                      {device.qr}{' '}
                      <FontAwesomeIcon icon={faCopy} style={{fontSize: 16}} />
                    </CopyWithMessage>
                    ,{' '}
                    <CopyWithMessage
                      inline
                      value={device.ref}
                      messageCopied='Copiado!'
                      messageTime={2000}
                    >
                      {device.ref}{' '}
                      <FontAwesomeIcon icon={faCopy} style={{fontSize: 16}} />
                    </CopyWithMessage>
                    , {device.data.sim_provider}
                    <br />
                    Creado:{' '}
                    {moment
                      .utc(device.created_at)
                      .local()
                      .format('YYYY-MM-DD HH:mm')}
                  </td>
                </tr>

                <tr>
                  <td>Status</td>
                  <td>
                    <div>
                      {statusTranslations[device.status] || device.status}
                      {device.subscription && (
                        <div>
                          <Link to={`/suscripcion/${device.subscription.id}`}>
                            #{device.subscription.id} -{' '}
                            {device.subscription.user?.name}
                          </Link>
                        </div>
                      )}
                    </div>
                    {device.data?.last_status_change_at && (
                      <div>
                        <small>
                          Últ cambio:{' '}
                          {
                            statusTranslations[
                              device.data?.last_status_change.original_status
                            ]
                          }
                          {' -> '}
                          {
                            statusTranslations[
                              device.data?.last_status_change.new_status
                            ]
                          }{' '}
                          (
                          <abbr
                            title={moment
                              .utc(device.data?.last_status_change_at)
                              .local()
                              .format('YYYY-MM-DD HH:mm:ss')}
                          >
                            {moment
                              .utc(device.data?.last_status_change_at)
                              .fromNow()}
                          </abbr>
                          )
                        </small>
                      </div>
                    )}

                    {device.status === 'stolen_suspect' && (
                      <div>
                        <Input.TextArea
                          rows={10}
                          value={statusSendMessage}
                          onChange={(e) => {
                            setStatusSendMessage(e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <CopyWithMessage
                          value={statusSendMessage}
                          messageCopied='Copiado!'
                          messageTime={2000}
                        >
                          <FontAwesomeIcon
                            icon={faCopy}
                            style={{fontSize: 16}}
                          />
                        </CopyWithMessage>
                      </div>
                    )}
                    {device.open_theft_incident && (
                      <>
                        {' '}
                        <Link
                          to={`/incidents?view=${device.open_theft_incident.id}`}
                        >
                          Incidente: {device.open_theft_incident.id} (Creado:{' '}
                          <abbr
                            title={moment
                              .utc(device.open_theft_incident.created_at)
                              .local()
                              .format('YYYY-MM-DD HH:mm:ss')}
                          >
                            {moment
                              .utc(device.open_theft_incident.created_at)
                              .fromNow()}
                            )
                          </abbr>
                        </Link>
                      </>
                    )}
                    {device.current_trip?.id && (
                      <>
                        <br />
                        Viaje:{' '}
                        <Link to={`/trip/${device.current_trip?.id}`}>
                          {device.current_trip?.id}
                        </Link>{' '}
                        - Inicio:{' '}
                        {moment
                          .utc(device.current_trip?.trip_start_at)
                          .fromNow()}{' '}
                        <br />
                        Usuario:{' '}
                        <Link
                          to={`/trips/?q=${device.current_trip?.user?.email}`}
                        >
                          {device.current_trip?.user?.name} -{' '}
                          {device.current_trip?.user?.email}{' '}
                        </Link>
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{
                            display: 'inline-block',
                            color: getTripQualificationColor(
                              device.current_trip?.user?.grin_qualification
                            ),
                          }}
                        />
                      </>
                    )}
                    {newAvailableStatuses &&
                      newAvailableStatuses.length > 0 && (
                        <div>
                          <Select
                            onChange={(v) => {
                              moveDevicesToStatus(v);
                            }}
                            options={newAvailableStatuses}
                            style={{width: '100%'}}
                            placeholder='Cambiar a...'
                          />
                          <div className={styles.setLocation}>
                            {!!changeLocationOnUpdate}
                            <Checkbox
                              defaultChecked={!!changeLocationOnUpdate}
                              onChange={(e) => {
                                setChangeLocationOnUpdate(e.target.checked);
                              }}
                            >
                              Poner en ubicación del cambio de estado
                            </Checkbox>
                          </div>
                        </div>
                      )}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <Button
                      size='medium'
                      block
                      onClick={() => onMarkReset()}
                      type='secondary'
                    >
                      <FontAwesomeIcon
                        icon={faWrench}
                        style={{marginRight: 5}}
                      />
                      Marcar como reseteado
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <Button
                      size='medium'
                      block
                      danger
                      onClick={() => onMarkNotFound()}
                      type='primary'
                    >
                      <FontAwesomeIcon icon={faBan} style={{marginRight: 5}} />
                      Marcar como NO ENCONTRADO
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <Button
                      size='medium'
                      block
                      onClick={() => onShowYourLocationMap()}
                      type='secondary'
                    >
                      <FontAwesomeIcon
                        icon={faMapPin}
                        style={{marginRight: 5}}
                      />
                      Confirmar ubicación
                    </Button>
                  </td>
                </tr>
                {hasIot && (
                  <>
                    <tr>
                      <td>Último Reset</td>
                      <td>
                        {device.data?.last_reset_at
                          ? moment
                              .utc(device.data?.last_reset_at)
                              .local()
                              .format('YYYY-MM-DD HH:mm:ss')
                          : 'Sin info'}
                      </td>
                    </tr>
                    <tr>
                      <td>Último No Encontrado</td>
                      <td>
                        {device.data?.last_not_found_at
                          ? moment
                              .utc(device.data?.last_not_found_at)
                              .local()
                              .format('YYYY-MM-DD HH:mm:ss')
                          : 'Sin info'}
                      </td>
                    </tr>
                  </>
                )}
                <tr>
                  <td>Último viaje finalizado</td>
                  <td>
                    {device.data?.last_trip ? (
                      <>
                        Viaje:{' '}
                        <Link to={`/trip/${device.data?.last_trip.id}`}>
                          {device.data?.last_trip.id}
                        </Link>
                        <br />
                        Inicio:{' '}
                        {moment
                          .utc(device.data?.last_trip.trip_start_at)
                          .fromNow()}{' '}
                        <br />
                        Fin:{' '}
                        {moment
                          .utc(device.data?.last_trip.end_at)
                          .fromNow()}{' '}
                        <br />
                        Usuario:{' '}
                        <Link
                          to={`/trips/?q=${device.data?.last_trip.user?.email}`}
                        >
                          {device.data?.last_trip.user?.name} -{' '}
                          {device.data?.last_trip.user?.email}{' '}
                        </Link>
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{
                            display: 'inline-block',
                            color: getTripQualificationColor(
                              device.data?.last_trip.user?.grin_qualification
                            ),
                          }}
                        />
                      </>
                    ) : device.data?.last_trip_ended ? (
                      `${moment.utc(device.data?.last_trip_ended).local().format('YYYY-MM-DD HH:mm:ss')} - ${moment.utc(device.data?.last_trip_ended).fromNow()} `
                    ) : (
                      'Sin info'
                    )}
                  </td>
                </tr>
                {user.can.manage_devices ? (
                  <>
                    <tr>
                      <td>Link</td>
                      <td>
                        <Link
                          to={`/device/${device.ref}`}
                        >{`/device/${device.ref}`}</Link>
                        <div style={{display: 'inline-block', marginLeft: 20}}>
                          <CopyWithMessage
                            value={`https://rental.grow.mobi/device/${device.ref}`}
                            messageCopied='Copiado!'
                            messageTime={2000}
                          >
                            <FontAwesomeIcon
                              icon={faCopy}
                              style={{fontSize: 16}}
                            />
                          </CopyWithMessage>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Ubicación</td>
                      <td>
                        {device.lat},{device.lng}
                        {' - '}
                        <abbr
                          title={moment
                            .utc(device.geo_updated_at)
                            .local()
                            .format('YYYY-MM-DD HH:mm:ss')}
                        >
                          {moment.utc(device.geo_updated_at).fromNow()}
                        </abbr>{' '}
                        <div style={{display: 'inline-block', marginLeft: 20}}>
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${device.lat},${device.lng}`}
                            target='_map'
                          >
                            {' '}
                            <FontAwesomeIcon icon={faExternalLinkAlt} /> Ver en
                            mapa
                          </a>
                        </div>
                        <div style={{display: 'inline-block', marginLeft: 20}}>
                          <CopyWithMessage
                            value={`https://www.google.com/maps/search/?api=1&query=${device.lat},${device.lng}`}
                            messageCopied='Copiado!'
                            messageTime={2000}
                          >
                            <FontAwesomeIcon
                              icon={faCopy}
                              style={{fontSize: 16}}
                            />
                          </CopyWithMessage>
                        </div>
                        {device.data?.out_of_area ? ' FUERA DE AREA' : ''}
                        {device.data?.instant_velocity_km_h !== -1 && (
                          <div>
                            Vel instantanea: {device.data.instant_velocity_km_h}{' '}
                            km/h
                          </div>
                        )}
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td>Ubicación</td>
                    <td>
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${device.lat},${device.lng}`}
                        target='_map'
                      >
                        <img
                          style={{maxWidth: '100%', width: 400}}
                          src={mapUrl}
                          alt='Map'
                        />
                        <div style={{textAlign: 'center', marginTop: 10}}>
                          <FontAwesomeIcon icon={faExternalLinkAlt} /> Ver en
                          mapa
                        </div>
                      </a>
                    </td>
                  </tr>
                )}

                <tr>
                  <td>Flota</td>
                  <td>
                    {user.can.manage_devices && hasAccessToDeviceFleet ? (
                      <Select
                        style={{width: '100%'}}
                        defaultValue={
                          hasAccessToDeviceFleet
                            ? hasAccessToDeviceFleet.id
                            : null
                        }
                        onChange={(v) => changeFleet(v)}
                      >
                        {fleets.map((x) => (
                          <Option key={x.id} value={x.id}>
                            {x.name}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <span>{device.fleet?.name || 'Sin Flota'}</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Branding</td>
                  <td>
                    {user.can.manage_devices &&
                    device.fleet_id &&
                    hasAccessToDeviceFleet &&
                    Object.keys(brands[device.fleet_id]).length > 1 ? (
                      <Select
                        style={{width: 200}}
                        defaultValue={device.brand}
                        onChange={(v) => changeBrand(v)}
                      >
                        {Object.keys(brands[device.fleet_id]).map((x) => (
                          <Option key={x} value={x}>
                            {brands[device.fleet_id][x]}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <span>Sin Branding</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Firmware</td>
                  <td>
                    {device.data.iot_firmware_version}{' '}
                    <Button
                      size='small'
                      onClick={() => onAction('request_firmware')}
                      type='secondary'
                    >
                      {updating && (
                        <FontAwesomeIcon
                          icon={faSync}
                          spin
                          style={{marginRight: 5}}
                        />
                      )}
                      Actualizar
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>Velocidad Máx</td>
                  <td>
                    {device.data.max_speed
                      ? `${device.data.max_speed}km/h`
                      : 'desconocida'}{' '}
                    <Button
                      size='small'
                      onClick={() => onAction('get_max_speed')}
                      type='secondary'
                    >
                      {updating && (
                        <FontAwesomeIcon
                          icon={faSync}
                          spin
                          style={{marginRight: 5}}
                        />
                      )}
                      Actualizar
                    </Button>{' '}
                    {user.can.devices_advanced_ops && (
                      <>
                        <Button
                          size='small'
                          onClick={() => onAction('set_max_speed', {speed: 15})}
                          type='secondary'
                        >
                          {updating && (
                            <FontAwesomeIcon
                              icon={faSync}
                              spin
                              style={{marginRight: 5}}
                            />
                          )}
                          Set 15km/h
                        </Button>{' '}
                        <Button
                          size='small'
                          onClick={() => onAction('set_max_speed', {speed: 25})}
                          type='secondary'
                        >
                          {updating && (
                            <FontAwesomeIcon
                              icon={faSync}
                              spin
                              style={{marginRight: 5}}
                            />
                          )}
                          Set 25km/h
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>IOT</td>
                  <td>
                    Batería: {device.data.batiot}
                    %
                    <br />
                    Conectado al Patín:{' '}
                    {parseInt(device.data?.is_scooter_present, 10) === 1
                      ? 'SI'
                      : 'Desconocido'}
                  </td>
                </tr>

                <tr>
                  <td>Heartbeat</td>
                  <td>
                    {device.data.last_message_from_device_at ? (
                      <>
                        <abbr
                          title={moment
                            .utc(device.data.last_heartbeat_from_device_at)
                            .local()
                            .format('YYYY-MM-DD HH:mm:ss')}
                        >
                          {moment
                            .utc(device.data.last_heartbeat_from_device_at)
                            .fromNow()}
                        </abbr>{' '}
                        -{' '}
                        {moment
                          .utc(device.data.last_heartbeat_from_device_at)
                          .local()
                          .format('YYYY-MM-DD HH:mm:ss')}
                        <br />
                        (p: {device.data.heartbeat_period}
                        s)
                      </>
                    ) : (
                      'Sin info'
                    )}{' '}
                    <br />
                    <Button
                      size='small'
                      onClick={() => onAction('set_heartbeat', {period: 20})}
                      type='secondary'
                    >
                      {updating && (
                        <FontAwesomeIcon
                          icon={faSync}
                          spin
                          style={{marginRight: 5}}
                        />
                      )}
                      Set 20s
                    </Button>{' '}
                    <Button
                      size='small'
                      onClick={() => onAction('set_heartbeat', {period: 600})}
                      type='secondary'
                    >
                      {updating && (
                        <FontAwesomeIcon
                          icon={faSync}
                          spin
                          style={{marginRight: 5}}
                        />
                      )}
                      Set 10m
                    </Button>{' '}
                    <Button
                      size='small'
                      onClick={() => onAction('force_heartbeat')}
                      type='secondary'
                    >
                      {updating && (
                        <FontAwesomeIcon
                          icon={faSync}
                          spin
                          style={{marginRight: 5}}
                        />
                      )}
                      Forzar HB
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>2G/4G, rssi, IMEI, iccid</td>
                  <td>
                    {`${networks[device.data['4g2g']] ? networks[device.data['4g2g']] : ''},${device.data.rssi ? device.data.rssi : ''} (${signalLevel}), ${device.data.imei ? device.data.imei : 'Unknown'}, ${device.data.iccid ? device.data.iccid : 'Unknown'}`}
                  </td>
                </tr>

                <tr>
                  <td>Últ Mensaje</td>
                  <td>
                    {device.data.last_message_from_device_at ? (
                      <>
                        <abbr
                          title={moment
                            .utc(device.data.last_message_from_device_at)
                            .local()
                            .format('YYYY-MM-DD HH:mm:ss')}
                        >
                          {moment
                            .utc(device.data.last_message_from_device_at)
                            .fromNow()}
                        </abbr>{' '}
                        -{' '}
                        {moment
                          .utc(device.data.last_message_from_device_at)
                          .local()
                          .format('YYYY-MM-DD HH:mm:ss')}
                        <br />
                        <div
                          style={{
                            fontFamily: 'monospace',
                            wordBreak: 'break-all',
                          }}
                        >
                          <small>
                            {JSON.stringify(
                              device.data?.last_message_from_device
                            )}
                          </small>
                        </div>
                      </>
                    ) : (
                      'Sin info'
                    )}
                  </td>
                </tr>
              </table>
            </>
          </TabPane>
          <TabPane tab={<>Ubicación</>} key='position'>
            <div
              className={styles.map}
              style={{
                width: '100%',
                maxWidth: 500,
                marginTop: 0,
              }}
            >
              <MapComponent
                key={device.id}
                zoom={16}
                fleetId={device.fleet_id}
                minimumClusterSize={1000}
                defaultCenter={{lat: device.lat, lng: device.lng}}
                minZoom={12}
                maxZoom={20}
                googleMapURL='https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places'
                containerElement={
                  <div
                    style={{
                      width: isMobile() ? '100%' : 500,
                      height: isMobile() ? window.innerHeight - 420 : 400,
                    }}
                  />
                }
                mapElement={
                  <div
                    style={{borderRadius: 5, width: '100%', height: '100%'}}
                  />
                }
                markers={markersInMap}
              />
              <div className={stylesLayout['2colsAuto']} style={{margin: 10}}>
                <div>
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${device.lat},${device.lng}`}
                    target='_map'
                  >
                    <div style={{marginTop: 10}}>
                      <FontAwesomeIcon icon={faExternalLinkAlt} /> Ver en mapa
                    </div>
                  </a>
                  <div className={styles.coordinates}>
                    <CopyWithMessage
                      inline
                      value={`${device.lat}, ${device.lng}`}
                      messageCopied='Copiado!'
                      messageTime={2000}
                    >
                      {device.lat}
                      {', '}
                      {device.lng}
                    </CopyWithMessage>
                  </div>
                  {device.data?.instant_velocity_km_h !== -1 && (
                    <div>
                      Vel instantanea: {device.data.instant_velocity_km_h} km/h
                    </div>
                  )}
                  <small>
                    {moment
                      .utc(device.geo_updated_at)
                      .local()
                      .format('DD/MM HH:mm:ss')}
                    {' - '}
                    {moment.utc(device.geo_updated_at).fromNow()}
                  </small>
                </div>
                <div>
                  <Button
                    onClick={() => onAction('request_geolocation')}
                    type='secondary'
                  >
                    {updating && <FontAwesomeIcon icon={faSync} spin />}
                    <FontAwesomeIcon icon={faLocationArrow} />
                    Act geoloc
                  </Button>
                </div>
              </div>
              <Button
                size='medium'
                block
                onClick={() => onShowYourLocationMap()}
                type='secondary'
              >
                <FontAwesomeIcon icon={faMapPin} style={{marginRight: 5}} />
                Confirmar ubicación
              </Button>
            </div>
          </TabPane>
          <TabPane tab={<>Acciones</>} key='actions'>
            <>
              <div className={styles.actions}>
                {showActions ? (
                  <>
                    <div
                      className={styles.commands}
                      style={{
                        gridTemplateColumns: `repeat(${isMobile() ? 2 : buttonCols}, 1fr)`,
                      }}
                    >
                      <Button
                        onClick={() => onAction('unlock')}
                        type='secondary'
                      >
                        {updating && <FontAwesomeIcon icon={faSync} spin />}
                        <FontAwesomeIcon icon={faUnlock} />
                        Desbloquear {device.qr}
                      </Button>
                      <Button onClick={() => onAction('lock')} type='secondary'>
                        {updating && <FontAwesomeIcon icon={faSync} spin />}
                        <FontAwesomeIcon icon={faLock} />
                        Bloquear {device.qr}
                      </Button>
                      <Button
                        onClick={() => onAction('buzz_on')}
                        type='secondary'
                      >
                        {updating && <FontAwesomeIcon icon={faSync} spin />}
                        <FontAwesomeIcon icon={faVolumeUp} />
                        Buzz On
                      </Button>
                      <Button
                        onClick={() => onAction('buzz_off')}
                        type='secondary'
                      >
                        {updating && <FontAwesomeIcon icon={faSync} spin />}
                        <FontAwesomeIcon icon={faVolumeMute} />
                        Buzz Off
                      </Button>
                      <Button
                        onClick={() => onAction('request_geolocation')}
                        type='secondary'
                      >
                        {updating && <FontAwesomeIcon icon={faSync} spin />}
                        <FontAwesomeIcon icon={faLocationArrow} />
                        Act geolocalización
                      </Button>
                      {user.can.devices_advanced_ops && (
                        <>
                          <Button
                            onClick={() => onAction('alerts_on')}
                            type='secondary'
                          >
                            {updating && <FontAwesomeIcon icon={faSync} spin />}
                            <FontAwesomeIcon icon={faExclamationCircle} />
                            Hab. Alertas
                          </Button>
                          <Button
                            onClick={() => onAction('alerts_off')}
                            type='secondary'
                          >
                            {updating && <FontAwesomeIcon icon={faSync} spin />}
                            Deshab. Alertas
                          </Button>

                          <Button onClick={() => onAction('power_off')} danger>
                            {updating && <FontAwesomeIcon icon={faSync} spin />}
                            <FontAwesomeIcon icon={faPowerOff} />
                            Apagar
                          </Button>
                        </>
                      )}
                    </div>
                    <div className={styles.colors}>
                      <div>Luces:</div>
                      <div className={styles.options}>
                        <div
                          className={`${styles.color} ${styles.off}`}
                          role='button'
                          onClick={() =>
                            onAction('change_light', {color: 'off'})
                          }
                          type='primary'
                          title='Apagadas'
                        />
                        <div
                          className={`${styles.color} ${styles.red}`}
                          role='button'
                          onClick={() =>
                            onAction('change_light', {color: 'red'})
                          }
                          type='primary'
                          title='Red'
                        />
                        <div
                          className={`${styles.color} ${styles.orange}`}
                          role='button'
                          onClick={() =>
                            onAction('change_light', {color: 'orange'})
                          }
                          type='primary'
                          title='Orange'
                        />
                        <div
                          className={`${styles.color} ${styles.yellow}`}
                          role='button'
                          onClick={() =>
                            onAction('change_light', {color: 'yellow'})
                          }
                          type='primary'
                          title='Yellow'
                        />
                        <div
                          className={`${styles.color} ${styles.green}`}
                          role='button'
                          onClick={() =>
                            onAction('change_light', {color: 'green'})
                          }
                          type='primary'
                          title='Green'
                        />
                        <div
                          className={`${styles.color} ${styles.blue}`}
                          role='button'
                          onClick={() =>
                            onAction('change_light', {color: 'blue'})
                          }
                          type='primary'
                          title='Blue'
                        />
                        <div
                          className={`${styles.color} ${styles.cyan}`}
                          role='button'
                          onClick={() =>
                            onAction('change_light', {color: 'cyan'})
                          }
                          type='primary'
                          title='Cyan'
                        />
                        <div
                          className={`${styles.color} ${styles.violet}`}
                          role='button'
                          onClick={() =>
                            onAction('change_light', {color: 'violet'})
                          }
                          type='primary'
                          title='Violet'
                        />
                        <div
                          className={`${styles.color} ${styles.pink}`}
                          role='button'
                          onClick={() =>
                            onAction('change_light', {color: 'pink'})
                          }
                          type='primary'
                          title='Pink'
                        />
                        <div
                          className={`${styles.color} ${styles.alert}`}
                          role='button'
                          onClick={() =>
                            onAction('change_light', {color: 'alert'})
                          }
                          type='primary'
                          title='Alerta'
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <Button onClick={() => setShowActions(true)} type='secondary'>
                    Mostrar acciones
                  </Button>
                )}

                {user.can.devices_advanced_ops && (
                  <div className={styles.mqtt_commands}>
                    <MqttSubscriptionList
                      topic={`s/c/${device.ref}`}
                      title='Commands'
                      height={300}
                      showResend
                      canSend={user.can.send_mqtt}
                    />
                    <MqttSubscriptionList
                      topic={`s/a/17/${device.ref}`}
                      title='Answers'
                      height={300}
                      showTranslations
                      canSend={user.can.send_mqtt}
                    />
                    <MqttSubscriptionList
                      topic={`s/h/17/${device.ref}`}
                      title='Heartbeats'
                      height={300}
                      canSend={user.can.send_mqtt}
                    />
                    {device.data?.iot_firmware_version >= 35004 && (
                      <MqttSubscriptionList
                        topic={`s/d/${device.ref}`}
                        title='Commands Backup'
                        height={300}
                        showResend
                        canSend={user.can.send_mqtt}
                      />
                    )}
                  </div>
                )}
              </div>
              <table className={styles.table}>
                <tr>
                  <td>Últ Mensaje</td>
                  <td>
                    {device.data.last_message_from_device_at ? (
                      <>
                        <abbr
                          title={moment
                            .utc(device.data.last_message_from_device_at)
                            .local()
                            .format('YYYY-MM-DD HH:mm:ss')}
                        >
                          {moment
                            .utc(device.data.last_message_from_device_at)
                            .fromNow()}
                        </abbr>
                        <br />
                        <div
                          style={{
                            fontFamily: 'monospace',
                            wordBreak: 'break-all',
                          }}
                        >
                          <small>
                            {JSON.stringify(
                              device.data?.last_message_from_device
                            )}
                          </small>
                        </div>
                      </>
                    ) : (
                      'Sin info'
                    )}
                  </td>
                </tr>
              </table>
            </>
          </TabPane>
          {hasIot && user.can.devices_advanced_ops && (
            <TabPane tab={<>Eventos</>} key='events'>
              <div className={styles.events}>
                <div className={styles.sectionTitle}>
                  Eventos recientes {device.qr}
                  {events && (
                    <span
                      onClick={() => loadEvents(50, null, true)}
                      style={{marginLeft: 10}}
                    >
                      <FontAwesomeIcon icon={faSync} spin={loadingEvents} />
                    </span>
                  )}
                </div>
                <div
                  className={styles.inner}
                  style={{height: window.innerHeight - 390}}
                >
                  {events ? (
                    <EventsList
                      events={events}
                      showMore={
                        <div>
                          {loadingEvents ? (
                            <div style={{margin: 25, textAlign: 'center'}}>
                              Cargando...
                            </div>
                          ) : (
                            <Button
                              type='secondary'
                              style={{width: '100%', marginTop: 10}}
                              onClick={() => {
                                loadEvents(
                                  200,
                                  events[events.length - 1].id,
                                  false
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faPlus}
                                style={{marginRight: 5}}
                              />
                              Cargar más eventos
                            </Button>
                          )}
                        </div>
                      }
                    />
                  ) : (
                    <div
                      onClick={() => loadEvents(50, null, true)}
                      className={styles.clickLoadArea}
                    >
                      {loadingEvents ? 'Cargando...' : <a>Cargar eventos </a>}
                    </div>
                  )}
                </div>
              </div>
            </TabPane>
          )}
        </Tabs>
      </div>
      <div className={styles.mainActions}>
        {device.data.locked === 1 ? (
          user.can.unlock_device && (
            <div
              onClick={() => onAction('unlock')}
              className={[styles.mainButton, styles.unlock].join(' ')}
            >
              {updating && <FontAwesomeIcon icon={faSync} spin />}
              <FontAwesomeIcon icon={faUnlock} />
              Desbloquear {device.qr}
            </div>
          )
        ) : (
          <div
            onClick={() => onAction('lock')}
            className={[styles.mainButton, styles.lock].join(' ')}
          >
            {updating && <FontAwesomeIcon icon={faSync} spin />}
            <FontAwesomeIcon icon={faUnlock} />
            Bloquear {device.qr}
          </div>
        )}
        <table className={styles.table}>
          <tr>
            <td>Últ Mensaje</td>
            <td>
              {device.data.last_message_from_device_at ? (
                <>
                  <abbr
                    title={moment
                      .utc(device.data.last_message_from_device_at)
                      .local()
                      .format('YYYY-MM-DD HH:mm:ss')}
                  >
                    {moment
                      .utc(device.data.last_message_from_device_at)
                      .fromNow()}
                  </abbr>
                  <br />
                  <div
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      fontFamily: 'monospace',
                      wordBreak: 'break-all',
                      whiteSpace: 'nowrap',
                      width: 200,
                    }}
                  >
                    <small>
                      {JSON.stringify(device.data?.last_message_from_device)}
                    </small>
                  </div>
                </>
              ) : (
                'Sin info'
              )}
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};
MobileScooterInfo.defaultProps = {
  updating: false,
  openActions: true,
  buttonCols: 8,
  pollPeriod: 10000,
};
MobileScooterInfo.propTypes = {
  user: PropTypes.object.isRequired,
  defaultDevice: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  fleets: PropTypes.array.isRequired,
  updating: PropTypes.bool,
  openActions: PropTypes.bool,
  buttonCols: PropTypes.number,
  pollPeriod: PropTypes.number,
};
export default MobileScooterInfo;
